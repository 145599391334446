import { Injectable } from "@angular/core";
import { DataBaseService } from "./data-base.service";
import { HttpClient } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";

import { MainProductDto } from "../model/MainProductDto";

import { Subject, Observable } from 'rxjs';
import { PromotionsDto } from "../model/Promotion";
import { ProductCategoryDto } from "../model/ProductCategoryDto";

@Injectable()
export class ProductsDataService extends DataBaseService {
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(httpClient: HttpClient) {
    super(httpClient);

    this.apiEndpoint += "/products";
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  getCategoryByKey(categoryKey: string): Observable<ProductCategoryDto[]> {

    let url = this.apiEndpoint + `/category/${categoryKey}`;

    return this.httpClient
      .get<MainProductDto[]>(url).pipe(
        map(response => this.convertDateStringsToDates(response)));
  }

  getProductsByCategoryKey(categoryKey: string): Observable<MainProductDto[]> {

    let url = this.apiEndpoint + `/category/${categoryKey}`;

    return this.httpClient
      .get<MainProductDto[]>(url).pipe(
        map(response => this.convertDateStringsToDates(response)));
  }

  getTopQuotedProductsByCategoryKey(categoryKey: string, topCount: number): Observable<MainProductDto[]> {

    let url = this.apiEndpoint + `/category/${categoryKey}/topquoted/${topCount}`;

    return this.httpClient
      .get<MainProductDto[]>(url).pipe(
        map(response => this.convertDateStringsToDates(response)));
  }

  getProductsPromotionsByCategoryKey(categoryKey: string): Observable<PromotionsDto[]> {

    let url = this.apiEndpoint + `/category/${categoryKey}/promotions`;

    return this.httpClient
      .get<PromotionsDto[]>(url).pipe(
        map(response => this.convertDateStringsToDates(response)));
  }


  getProductByName(urlEncodedName: string, width: string = null, depth: string = null): Observable<MainProductDto> {

    let url = this.apiEndpoint + `/${urlEncodedName}`;

    if (width && depth) {
      url += `/${width}/${depth}`;
    }

    return this.httpClient
      .get<MainProductDto>(url)
      .pipe(
        map(response => this.convertDateStringsToDates(response)));
  }

  getDetailBySize(categoryKey: string, width: number, depth: number): Observable<any> {

    let url = this.apiEndpoint + `/category/${categoryKey}/${width}/${depth}`;
    return this.httpClient
      .get<MainProductDto>(url).pipe(
        map(response => this.convertDateStringsToDates(response)));
  }
}
